<template>
    <div class="sale">
        <div v-for="product in products" :key="product.productId" class="product">
            <div class="product-name">
                {{ product.productName }}
            </div>
            <div class="product-token">
                {{ product.quota }}<span class="product-token-subscript">(条)</span>
            </div>
            <div class="product-price">
                <span style="color: #af0000; font-size: 20px;">￥{{ product.price.toFixed(2) }}</span>
            </div>
            <div class="product-buy" @click="payOrder(product.productId)">
                立即购买
            </div>
            <div class="product-desc">
                <span>{{ product.productDesc }}</span>
            </div>
        </div>
        <div v-if="showModal" class="product-pay">
            <div class="product-pay-weixin">
                微信扫码支付
            </div>
            <div class="product-pay-url">
                <qrcode-vue :value="payUrl" :size="250" level="H"></qrcode-vue>
            </div>
            <div class="product-pay-close" @click="handleCloseModal()">
                😁 支付完成，点击关闭。去对话
            </div>
        </div>
<!--        <div class="product-pay-prompt" v-show="showModal">-->
<!--        <span>支付成功，自动充值。可直接去<span style="color: rgb(0,0,0); font-weight: bold;" @click="navigateToChat">【对话】</span>使用</span>-->
<!--        </div>-->
    </div>
</template>

<script>

import QrcodeVue from 'qrcode-vue';
import { debounce } from 'lodash';

export default {
    name: 'Sale', // 组件的名称
    components: {
        QrcodeVue // 注册QrcodeVue组件
    },
    data() {
        return {
            products: [], // 产品列表
            showModal: false, // 控制支付模态框的显示
            payUrl: '' // 支付链接
        };
    },
    methods: {
        // 查询产品列表的方法
        async queryProductListHandle() {
            const token = sessionStorage.getItem("accessToken");
            this.$http({
                url: "weixin/pay/query_product_list",
                method: "GET",
                headers: {
                    'Accesstoken': `${token}`
                }
            }).then((res) => {
                this.products = res;
            })
        },

        // 创建支付订单的方法
        async payOrder(productId) {
            const token = sessionStorage.getItem("accessToken");
            this.$http({
                url: "weixin/pay/create_pay_order",
                method: "POST",
                headers: {
                    'Accesstoken': `${token}`
                },
                params: {
                    productId: productId
                }
            }).then((res) => {
                this.payUrl = res;
                this.showModal = true; // 显示支付模态框
            })
        },
        // 关闭支付模态框的方法
        handleCloseModal() {
            this.showModal = false;
        },
        // 跳转到聊天页面的方法
        navigateToChat() {
            this.$router.push('/chat');
        }
    },
    mounted() {
        // 组件挂载后查询产品列表
        this.queryProductListHandle();
    }
};

</script>

<style scoped>
.sale {
    display: flex;
    flex-wrap: wrap;
    font-family: "黑体", sans-serif;
    overflow-y: scroll;
}

.product {
    width: 250px;
    border-radius: 10px;
    position: relative;
    margin: 20px auto 0;
    display: block;
    flex-wrap: wrap;
    background-color: #f8f8f8;
}

.product-pay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* 使用transform来居中模态框 */
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column; /* 使用列布局 */
    align-items: center; /* 水平居中 */
    justify-content: center; /* 垂直居中 */
    padding: 20px; /* 添加一些内边距 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.product-pay-url {
    /* 移除固定宽度和高度，以及背景颜色 */
    /* background-color: #0ba800; */
    /* width: 250px; */
    /* height: 300px; */
    /* border-radius: 20px; */
}


.product-name {
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    margin: 30px auto 0;
    text-align: center;
}

.product-token {
    justify-content: center;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: #020202;
    margin: 25px auto 0;
}

.product-token-subscript {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
}

.product-desc {
    margin: 0 auto 10px;
    width: 220px;
    font-size: 12px;
    text-align: center;
}

.product-price {
    width: 250px;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #020202;
    margin: 20px auto 0;
}

.product-buy {
    margin: 20px auto;
    width: 180px;
    font-size: 18px;
    background-color: #018d01;
    color: #ffffff;
    border-radius: 4px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
}


.product-pay-weixin {
    font-size: 18px;
    font-weight: bold;
    color: #333; /* 更改文字颜色 */
    margin-bottom: 10px; /* 添加底部外边距 */
}

.product-pay-close {
    margin-top: 10px; /* 添加顶部外边距 */
    background-color: #3b2300;
    color: #f6f6f6;
    border-radius: 50px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
}
</style>
